import React from 'react'
import { useTranslate } from 'react-admin'
import TextField from '@material-ui/core/TextField'
import { useStyles } from './LoginPage.styled.js'

const ForgotPassword = ({
  email,
  setEmail,
  code,
  setCode,
  newPass,
  setNewPass,
  passwordSubmit
}) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <>
      {!passwordSubmit ? (
        <TextField
          required
          label={translate('ra.auth.username')}
          type='email'
          className={classes.field}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      ) : (
        <>
          {translate('auth.forgot_password_instructions')}
          <TextField
            required
            label={translate('auth.code')}
            type='text'
            className={classes.field}
            value={code}
            onChange={e => setCode(e.target.value)}
          />
          <TextField
            required
            label={translate('auth.new_password')}
            type='password'
            className={classes.field}
            value={newPass}
            onChange={e => setNewPass(e.target.value)}
          />
        </>
      )}
    </>
  )
}

export default ForgotPassword
