import React from 'react'
import { useTranslate } from 'react-admin'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import { useStyles } from './LoginPage.styled.js'

const LoginForm = ({
  email,
  setEmail,
  password,
  setPassword,
  setWhichForm
}) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <>
      <TextField
        required
        label={translate('ra.auth.username')}
        type='email'
        className={classes.field}
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <TextField
        required
        label={translate('ra.auth.password')}
        type='password'
        className={classes.field}
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <Link
        href='#' className={classes.forgotPassword} onClick={(e) => {
          e.preventDefault()
          setWhichForm('FORGOT_PASSWORD')
        }}
      >
        Olvide mi contraseña
      </Link>
    </>
  )
}

export default LoginForm
