import React, { useState } from 'react'
import { useLogin, useNotify, useTranslate, Notification } from 'react-admin'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import LockIcon from '@material-ui/icons/Lock'
import CardMedia from '@material-ui/core/CardMedia'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import LoginForm from './LoginForm'
import ForgotPassword from './ForgotPassword'
import { useStyles } from './LoginPage.styled.js'

const logo = require('../common/logo-text.svg')

const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [newPass, setNewPass] = useState('')
  const [code, setCode] = useState('')
  const [whichForm, setWhichForm] = useState('LOGIN')
  const login = useLogin()
  const notify = useNotify()
  const translate = useTranslate()
  const classes = useStyles()

  const buttonText = (whichForm === 'LOGIN') ? translate('ra.auth.sign_in') : translate('ra.navigation.next')

  const submit = (e) => {
    e.preventDefault()
    login({ email, password })
      .catch(() => notify('Correo o contraseña invalidos'))
  }

  return (
    <Box className={classes.container}>
      <Notification autoHideDuration={3000} />
      <Box className={classes.form}>
        <Box className={classes.formHeader}>
          <CardMedia src={logo} component='img' className={classes.logo} />
        </Box>
        {whichForm !== 'LOGIN' && (
          <KeyboardBackspaceIcon
            className={classes.backIcon}
            onClick={() => setWhichForm('LOGIN')}
            color='primary'
          />
        )}
        {whichForm === 'LOGIN' && (
          <LoginForm
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            setWhichForm={setWhichForm}
          />
        )}
        {whichForm === 'CHANGE_PASSWORD' && (
          <>
            <TextField
              label={translate('auth.new_password')}
              type='password'
              value={newPass}
              onChange={e => setNewPass(e.target.value)}
            />
          </>
        )}
        {(whichForm === 'FORGOT_PASSWORD' || whichForm === 'FORGOT_PASSWORD_SUBMIT') && (
          <ForgotPassword
            email={email}
            setEmail={setEmail}
            code={code}
            setCode={setCode}
            newPass={newPass}
            setNewPass={setNewPass}
            passwordSubmit={whichForm === 'FORGOT_PASSWORD_SUBMIT'}
          />
        )}
        <Button
          className={classes.button}
          size='small'
          variant='contained'
          color='primary'
          onClick={submit}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  )
}

export default LoginPage
