import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  DateInput,
  ShowButton
} from 'react-admin';
import HomeIcon from '@material-ui/icons/Home'
export const InmueblesIcon = HomeIcon

export const InmueblesList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="titulo" />
      <TextField source="descripcion" />
      <TextField source="precio" />
      <TextField source="tipoInmueble" />
      <EditButton basePath="/inmuebles" />
      <ShowButton basePath="/inmuebles" />
    </Datagrid>
  </List>

);

const InmueblesTitle = ({ record  }) => {
  return <span>Post {record ? `"${record.title}"` : ''}</span>;

};

export const InmueblesEdit = (props) => (
  <Edit title={<InmueblesTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="title" />
      <TextInput source="teaser" options={{ multiLine: true  }} />
      <TextInput multiline source="body" />
      <DateInput label="Publication date" source="published_at" />
      <TextInput source="average_note" />
      <TextInput disabled label="Nb views" source="views" />
    </SimpleForm>
  </Edit>

);

export const InmueblesCreate = (props) => (
  <Create title="Create a Post" {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="teaser" options={{ multiLine: true  }} />
      <TextInput multiline source="body" />
      <TextInput label="Publication date" source="published_at" />
      <TextInput source="average_note" />
    </SimpleForm>
  </Create>
)
