import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
    backgroundColor: '#1646B5'
  },
  backIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '30px'
  },
  field: {
    marginBottom: 10,
    marginTop: 10,
    width: '250px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    justifyContent: 'center',
    border: '1px solid #000',
    backgroundColor: '#FFF',
    width: '250px',
    position: 'relative'
  },
  formHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '120px'
  },
  button: {
    marginTop: '20px',
    height: '40px'
  },
  forgotPassword: {
    textAlign: 'right',
    marginTop: '10px',
    fontSize: '14px'
  },
  logo: {
    width: '100px',
    height: '100px'
  }
})
