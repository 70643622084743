import React from 'react';
import {
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  DateField,
  RichTextField,
  NumberField,
  FunctionField,
  DataGrid
} from 'react-admin'

const InmueblesShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label='Resumen'>
        <TextField source="titulo" />
        <RichTextField source="descripcion" />
        <DateField label="Publication date" source="created_at" />
        <NumberField source="destacado" />
        <TextField source="email" />
        <TextField source="tipoInmueble" />
        <NumberField
          source="precioMantenimiento"
          options={{ style: 'currency', currency: 'MXN' }}/>
        <NumberField
          source="precio"
          options={{ style: 'currency', currency: 'MXN' }}/>
        <NumberField source="areaConstruida" />
        <NumberField source="antiguedad" />
        <NumberField source="numeroHabitaciones" />
        <NumberField source="numeroBanos" />
        <NumberField source="numeroEstacionamiento" />
        <FunctionField
          label='Publicado por'
          render={record => `${record.nombre} ${record.apellido}`} />
      </Tab>
      <Tab label='Amenidades'>
        <NumberField source='mascotas' />
        <NumberField source='balcon' />
        <NumberField source='terraza' />
        <NumberField source='jardin' />
        <NumberField source='patio' />
        <NumberField source='amueblado' />
        <NumberField source='estacionLavado' />
        <NumberField source='aireAcondicionado' />
        <NumberField source='calefaccion' />
        <NumberField source='accesoUniversal' />
        <NumberField source='cocinaIntegral' />
        <NumberField source='salonFiestas' />
        <NumberField source='cuartoServicio' />
        <NumberField source='seguridad' />
        <NumberField source='gym' />
        <NumberField source='cisterna' />
        <NumberField source='asador' />
        <NumberField source='jacuzzi' />
        <NumberField source='lobby' />
        <NumberField source='sauna' />
        <NumberField source='alberca' />
        <NumberField source='elevador' />
        <NumberField source='lobby' />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default InmueblesShow;
