import React from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import {
  InmueblesList,
  InmueblesEdit,
  InmueblesCreate,
  InmueblesIcon
} from './inmuebles';
import InmueblesShow from './inmuebles/InmueblesShow'
import authProvider from './authProvider'
import LoginPage from './Login/LoginPage'
import './App.css';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  if(token) options.headers.set('x-access-token', token);
  return fetchUtils.fetchJson(url, options);
};

function App() {
  return (
    <Admin
      authProvider={authProvider}
      loginPage={LoginPage}
      dataProvider={jsonServerProvider('http://api.flashome.mx/admin', httpClient)}>
      {permissions => [
        <Resource
          options={{ label: 'Inmuebles' }}
          name="inmuebles"
          show={InmueblesShow}
          list={InmueblesList}
          icon={InmueblesIcon}/>
      ]}
    </Admin>
  );
}

export default App;
